import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { GlobalLayout, WorkLayout } from 'components/Layout';
import { Banner, Images, Header } from 'components/Works';

function PharmPortal() {
  return (
    <GlobalLayout>
      <WorkLayout>
        <Banner
          render={({ onLoad, onStartLoad }) => (
            <StaticImage
              placeholder='none'
              onStartLoad={onStartLoad}
              onLoad={onLoad}
              alt='Desktop main page'
              src='../../images/pharm.png'
            />
          )}
        />

        <Header title='Pharm Portal' />

        <p>
          Gathering information about pharmaceuticals is difficult. There are
          multiple government-based sources of information, that sometimes
          contain mistakes, may overload and fail and generally can’t provide a
          good user experience for people who rely on that information.
          PharmPortal gathers the information from multiple services, verifies
          it, makes corrections and provides users with a convenient UI and API
          for integrations.
        </p>
        <h2>The goal</h2>
        <p>
          The first goal was to collect data from government’s online resources,
          normalising it, saving to the database and apply updates from next
          parsings. This was meant to be used internally within the company, but
          later it appeared that this information can also be useful for other
          businesses. The first concept was developed and launched, but with the
          desire to add new services it turned out that the current architecture
          was not suitable for expanding. Therefore, the next goal was to
          reinvent the architecture both for the backend and the frontend and
          release the second version of the portal.
        </p>
        <h2>My role</h2>
        <p>
          Initially I started working on the project as the lead frontend
          developer with 2 other developers under my management. The first
          version was successfully released and soon after I was offered to work
          on the second version. At that time I have founded my own team, called
          Atamore and continued working as the lead developer, supervising and
          teaching 2 developers of my own team. However, in spite of the client
          being a pharmaceutical company, the company wanted to created their
          own IT department, that would later continue the development and
          support of the project. We have decided that it would be best to
          integrate new developer from the client’s side into our team and work
          as one unit, but allowing new people to learn the codebase and later
          take the lead of the project. At that time I was managing 4 developers
          and also taking part in designing the system and the UI. After a few
          years of collaboration, the project was finally delegated entirely in
          the hands of the company’s employees, which for me as a great success,
          as we managed to help the client to become an IT company.
        </p>

        <Images description='Engagements table'>
          <StaticImage alt='AIS VA' src='../../images/aisva/screen-1.png' />
        </Images>

        <h2>Technologies</h2>
        <p>
          I will focus on describing the architecture of the second version of
          the project, as the first version was mainly done as a
          proof-of-concept and didn’t require complicated solutions.
        </p>
        <p>
          The backbone of the frontend is React.js, as it became almost a
          standard for the frontend development. But as SEO is one of the major
          requirements of the project, React is used along with Server-Side
          Rendering. The project is split on multiple services, each developed
          independently from each other, but sharing the core codebase, which
          defines and initialises the stack and also a UI library, which was
          created from scratch.
        </p>
        <p>
          The UI library is created with Storybook, which is used for testing
          out the components and as a documentation for developers.
          Styled-components is used for styling and theming, as it is important
          to base all the components on the same color scheme and typography
          presets. But unfortunately the UI library is not typed, as the initial
          attempt to use Flow has failed and typescript was not yet stable
          enough to perform a second attempt to implement static typing. The
          final set of components is then packaged and published into the
          private NPM repository, from where it is installed to other projects.
          In combination with splitting the library into ESM and CJS builds we
          were able to achieve JS bundles that include only the components used.
        </p>
        <p>
          Another major part is the API Gateway, also known as a Backend for
          frontend. It was needed because the Backend was split into multiple
          microservices, but in the majority of cases the data from one
          microservice needed to be enriched with information from another. As a
          solution we created a BFF service with Node.js, that was aggregating
          and enriching data and providing export to excel functionality based
          on queues and shared table schema. That schema was needed to mirror
          the representation of the data table on the frontend and its’ state in
          the export, as the table’s view can be customised by the user.
        </p>
        <p>The final stack looked like this:</p>
        <ul>
          <li>React.js with Server-Side Rendering</li>
          <li>Redux for state management</li>
          <li>Styled-components for styling and theming</li>
          <li>UI components library with Storybook as a NPM package</li>
          <li>Node.js BFF built with Adonis.js</li>
          <li>RabbitMQ for file export queues</li>
        </ul>

        <Images description='Findings form'>
          <StaticImage alt='AIS VA' src='../../images/aisva/screen-2.png' />
        </Images>

        <h2>Result</h2>
        <p>
          The project was successfully released both in its first and second
          iteration, which contains multiple public and private services. I’ve
          helped the company to hire and integrate their own developers, leading
          and teaching with along the way. And after several years of work the
          project was fully returned for further development to the client.
        </p>
      </WorkLayout>
    </GlobalLayout>
  );
}

export default PharmPortal;
